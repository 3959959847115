/** @jsx jsx */

import { jsx, Heading } from "theme-ui"
import {
  ContentBlock,
  HeaderSpacing,
  MapImageGlobal,
} from "../../components/page"
import { Trans } from "react-i18next"

export default () => {
  return (
    <ContentBlock>
      <MapImageGlobal />
      <HeaderSpacing />
      <Heading variant="headings.1" sx={{ marginBottom: "8px" }}>
        <Trans>
          Thank You
        </Trans>
      </Heading>
      <div sx={{ marginBottom: "2em", fontSize: "20px" }}>
        <Trans>
          Thank you for your support.
        </Trans>
      </div>
      <Heading variant="headings.2" sx={{ marginBottom: "8px" }}>
        <Trans>
          Share this cause
        </Trans>
      </Heading>
      <div sx={{ fontSize: "20px" }}>
        <Trans>
          The most important thing you can do now is share this cause as widely as possible.
        </Trans>
      </div>
    </ContentBlock>
  )
}
